import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
// FRAMER MOTION
import { motion, AnimatePresence } from "framer-motion";
//REDUX
import { useSelector } from "react-redux";

function Products() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const getID = useSelector((state) => state.getID);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await axios.get(
          `https://aromabakeryandcafe.uz/categories/${getID}`
        );

        setData(response.data.products);
      } catch (error) {
        console.error(error.message);
      }
      setLoading(true);
    };

    fetchData();
  }, [getID]);

  return (
    <>
      {getID.length === 0 ? (
        <span className="flex justify-center items-center h-screen">Empty</span>
      ) : (
        <div className="grid grid-cols-[repeat(auto-fill,_95px)] justify-center sm:grid-cols-[repeat(auto-fill,_120px)] m-auto p-4 gap-7 max-w-2xl">
          {loading
            ? data.map((item, i) => (
                <Link to={`/products/${item.id}`} key={item.id}>
                  <AnimatePresence>
                    <motion.div
                      className="font-medium"
                      initial={{
                        y: "20px",
                        opacity: 0,
                      }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{
                        duration: 0.2,
                        delay: 0.2 + i / 5,
                      }}
                    >
                      <div className="bg-[#F2F8FF] overflow-hidden rounded-2xl w-[97px] sm:w-[120px] h-[95px] sm:h-[120px]">
                        <img
                          src={item.media}
                          className="w-full h-full object-cover"
                          alt=""
                        />
                      </div>
                      <h3 className="text-xs sm:text-base sm:mt-4 mt-2 dark:text-white">
                        {item.title}
                      </h3>
                      <div className="text-[#898686] text-[10px] flex mt-1 sm:text-xs flex-col">
                        {/* <span>
                          {item.properties.map((propertie) => (
                            <span className="font-bold" key={propertie.id}>
                              {propertie.value}
                            </span>
                          ))}
                        </span> */}
                        <span>{item.price} UZS</span>
                      </div>
                    </motion.div>
                  </AnimatePresence>
                </Link>
              ))
            : Array(6)
                .fill(0)
                .map((e, index) => (
                  <div key={index + 1} className="animate-pulse">
                    <div className="bg-[#d9d9d9] w-[97px] sm:w-[120px] h-[95px] sm:h-[120px] rounded-2xl"></div>
                    <div className="h-3 sm:h-5 bg-[#d9d9d9] rounded sm:mt-5 mt-3"></div>
                    <div className="h-2 sm:h-3 bg-[#d9d9d9] rounded mt-2"></div>
                  </div>
                ))}
        </div>
      )}
    </>
  );
}

export default Products;
